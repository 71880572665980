.standardButton {
    color: black;
    border-radius: var(--border-radius-full);
    padding: var(--spacing-sm) var(--spacing-md);
    gap: 10px;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: var(--text-md);
    justify-content: center;
}

.standardButton:disabled {
    background-color: lightgray !important;
}

.standardButtonPrimary {
    border: 1px solid var(--col-lavendar-dark);
    color: var(--col-lavendar-dark);
}

.standardButtonSecondary {
    background-color: var(--col-lavendar);
}

.standardButtonWhite {
    border: 1px solid white;
    color: white;
}

.standardLinkButton__disabled {
    color: var(--col-black-60);
}

.tabBar {
    background-color: var(--col-primary-40);
    border: 1px solid var(--col-primary);
    color: var(--col-black);
    display: flex;
    gap: var(--spacing-md);
    padding: 4px;
    border-radius: var(--border-radius-md);
}

.tabButton {
    color: var(--col-black);
    border-radius: var(--border-radius-full);
    padding: 8px 16px;
    display: flex;
    align-items: center;
    font-size: var(--text-md);
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tabButtonSelected {
    color: black;
    border: 1px solid var(--col-primary);
    background-color: white;
}

.iconButton {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconButton:disabled {
    cursor: default;
}