.drawer {
  background-color: white;
  box-shadow: var(--boxshadow);
  height: calc(100vh - var(--header-height));
  width: 640px;
  position: absolute;
  top: var(--header-height);
  right: 0;
  bottom: 0;
  z-index: 100;
  padding: var(--spacing-lg);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  animation: slide 0.5s forwards;
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}



@keyframes slide {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.drawerContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  padding: 24px;
  overflow-y: scroll;
}

.visitHoursListWrapper {
  color: var(--col-grey-4);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  justify-content: center;
  overflow: hidden;
}

.visitHoursBody {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  justify-content: center;
}

.visitHoursListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 192px;
  font-size: var(--text-sm);
}

.timelineTimepoints {
  font-size: var(--text-sm);
}

.modalTitle {
  font: var(--font-h2);
  color: var(--col-black);
}

.modalSubTitle {
  font: var(--font-h3);
  color: var(--col-black);
}

.infoTextLines {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
}

.drawerBlock {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.subTasksList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.subTaskWrapper {
  background-color: var(--col-grey-1);
  border-radius: var(--border-radius);
  padding: var(--spacing-md);
}

.preferenceList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.preferenceSelectedItem {
  background-color: var(--col-grey-1);
  height: 40px;
  width: calc(100% + 16px);
  position: absolute;
  top: 0;
  left: -16px;
  border-radius: var(--border-radius-full);
}

.preferenceItem {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius-full);
  position: relative;
}


.preferenceItemStatus {
  font: var(--font-body-400);
  display: flex;
  justify-content: center;
  gap: var(--spacing-sm);
  align-items: center;
  z-index: 100;
}

.preferenceItemName {
  z-index: 100;
}

.preferenceItemStatusTitle {
  font: var(--font-body-400);
  color: var(--col-grey-4);
}

/* Task dependency stuff */

.taskDependency {
  background-color: var(--col-after-eight-30);
  gap: 24px;
  padding: 16px 10px 24px 16px;
  border-radius: 5px;
  border-left: 4px solid var(--col-after-eight);
  ;
}

.hasViolations {
  background-color: var(--col-dirt-rose-20);
  border-left: 4px solid var(--col-dirt-rose-dark);
}

.taskInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.taskTimeContainer {
  display: flex;
  min-width: 120px;
  justify-content: space-around;
  margin-right: 45px;
}

.taskTimeContainerTimeColumn {
  display: flex;
  flex-direction: column;
}

.employeeName {
  font-weight: bold;
}