.colouredRoundWrapper {
    /*border-radius: 50px;*/
    border-radius: var(--border-radius-full);
    color: white;
    gap: 10px;
    display: flex;
    align-items: center;
    font-size: var(--text-md);
}

.wrapperPrimaryColor {
    background-color: var(--col-primary-1);
    border-radius: var(--border-radius);
    padding: var(--spacing-md);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg)
}



.mainWhiteWrapper {
    background-color: white;
    border: var(--col-grey) 1px solid;
    border-radius: var(--border-radius);
    padding: var(--spacing-md)
}