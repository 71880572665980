.verticalTimelineColumn {
    display: flex;
    flex-direction: column;
    color: var(--col-black-60);
    font-size: var(--text-sm);
    border-right: 1px solid var(--col-grey);
}

.verticalSvgWrapper {
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
}

.verticalColumnTextItemsWrapper {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    font-size: var(--text-sm);
    background-color: white;
    z-index: 100;
    border-bottom: 1px solid var(--col-grey);
}

.verticalGanttChartColumn {
    max-width: 200px;
    /* Todo: fix by adding timeline instead */
    border-right: 1px solid var(--col-mocha-flat-white);
}

.verticalTextRow {
    display: flex;
    flex-direction: column;
    z-index: 40;
    position: absolute;
    height: 105px;
    width: fit-content;
}

.verticalTitleTextRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 34px;
    border-right: 1px solid var(--col-grey);
    padding: var(--spacing-xs);
    position: relative;
    background-color: "white";
}

.verticalTitleTextRow p {
    display: block;
    text-align: left;
    overflow: hidden;
    /* Hide overflow content */
    white-space: nowrap;
    /* Prevent text wrapping */
    text-overflow: ellipsis;
}

.verticalTitleLockIconWithPointer {
    cursor: pointer;
}

.verticalTitleLockIconHidden {
    display: none;
}

.overlayForBlockedShift {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--col-grey-4);
    opacity: 0.2;
    pointer-events: none;
    z-index: 10;
}

.foldMultipleKpi {
    height: 105px;
    animation-duration: 0.3s;
    animation-name: foldMultipleKpi;
    animation-fill-mode: forwards;
    overflow: hidden;
}

@keyframes foldMultipleKpi {
    0% {
        height: 105px
    }

    100% {
        height: 0px
    }
}

.unfoldMultipleKpi {
    height: 0px;
    animation-name: unfoldMultipleKpi;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    pointer-events: none;
    overflow: hidden;
}

@keyframes unfoldMultipleKpi {
    0% {
        height: 0px
    }

    100% {
        height: 105px
    }
}

.foldCollapsedKpi {
    height: 35px;
    animation-duration: 0.1s;
    animation-name: foldCollapsedKpi;
    animation-fill-mode: forwards;
    overflow: hidden;
}

@keyframes foldCollapsedKpi {
    0% {
        height: 35px
    }

    100% {
        height: 0px;
        display: none
    }
}

.unfoldCollapsedKpi {
    height: 0px;
    animation-name: unfoldCollapsedKpi;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    pointer-events: none;
    overflow: hidden;
}

@keyframes unfoldCollapsedKpi {
    50% {
        height: 0px
    }

    100% {
        height: 35px
    }
}

.centeredCell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 34px;
    border-right: 1px solid var(--col-grey);
    padding: var(--spacing-xs);
    position: relative;
}

.centeredCell p {
    display: block;
    text-align: left;
    overflow: hidden;
    /* Hide overflow content */
    white-space: nowrap;
    /* Prevent text wrapping */
    text-overflow: ellipsis;
}

.columnTitleCell {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 76px;
    padding: var(--spacing-xs);
    font-size: var(--text-sm);
    border-bottom: 1px solid var(--col-grey);
    border-right: 1px solid var(--col-grey);
    opacity: 0.5;
}

.bulletPoint {
    height: 3px;
    min-width: 3px;
    background-color: var(--col-black);
    border-radius: 20px;
}

.kpiBullet {
    height: 14px;
    width: 14px;
    border-radius: 50%;
}

.kpiChip {
    width: fit-content;
    padding: var(--spacing-sm);
    height: 25px;
    background-color: var(--col-grey-1);
    color: var(--col-black);
    border-radius: var(--border-radius-full);
    display: flex;
    gap: var(--spacing-sm);
    align-items: center;
}