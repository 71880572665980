.teamgroupContainer {
    width: 100%;
    display: flex;
}

.teamgroupTeamWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--spacing-sm);
}

.teamgroupTeamTitle {
    color: var(--col-mocha-cappucino);
    font: var(--font-h4);
}

.teamgroupTeamBar {
    background-color: var(--col-mocha-late);
    border-top-left-radius: var(--border-radius-xl);
    border-top-right-radius: var(--border-radius-xl);
    height: 8px;
    width: 100%;
}