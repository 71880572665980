.toastWrapper {
    position: absolute;
    top: auto;
    bottom: 30px;
    right: 30px;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
    z-index: 1000;
}

.toast {
    width: 336px;
    min-height: 91px;
    border-radius: var(--border-radius-md);
    padding: var(--spacing-sm) var(--spacing-md);
    background-color: var(--col-error-2);
    color: var(--col-error-3);
    animation: slideInFromRight 0.5s ease-out forwards;

}

.toastTitle {
    font-weight: 700 !important;
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}