/* TrippleSwitch */
.trippleSwitchLabel {
    width: 100%;
    display: flex;
    gap: var(--gap-sm);
}

.trippleSwitch {
    background-color: white;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100px;
    padding: 2px;
    height: 38px;
    border-radius: var(--border-radius-full);
    border: 1px solid var(--col-grey-2);
}

.trippleSwitchButton {
    width: 32px;
    height: 32px;
    display: flex;
    border-radius: var(--border-radius-full);
    justify-content: center;
    align-items: center;
}




/* Single switch */

.switchLabel {
    width: 100%;
    display: flex;
    gap: var(--gap-sm);
}

.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--col-grey-6);
    transition: .4s;
}

.slider:hover {
    background-color: var(--col-grey-8) !important;
}

.slider.disabled {
    background-color: var(--col-grey-4) !important;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
}

.sliderCircleIconLine {
    position: absolute;
    content: "";
    height: 10px;
    width: 2px;
    background-color: var(--col-brand);
    left: 11px;
    bottom: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .4s;
}

.sliderCircleIconLine.disabled {
    background-color: var(--col-grey-8);
}

.sliderCircleIconCircle {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    border: 2px solid var(--col-grey-11);
    border-radius: 30px;
    left: 7px;
    bottom: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .4s;
}

.sliderCircleIconCircle.disabled {
    border: 2px solid var(--col-grey-8);
}


input:checked~.sliderCircleIconLine,
input:checked~.sliderCircleIconCircle {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}


input:checked+.slider {
    background-color: var(--col-brand)
}



input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}


.slider.round:before {
    border-radius: 50%;
}