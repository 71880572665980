.searchInputField {
    font: var(--font-body-2);
    color: var(--col-grey-4);
}

.searchSugeestionsWrapper {
    border-radius: var(--border-radius-xl);
    background-color: white;
    gap: var(--spacing-lg);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--text-md);
    position: absolute;
    z-index: 9999;
    width: 640px;
    border: 1px solid var(--col-grey-2);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    overflow-y: "auto";
    max-height: 300;
    padding: var(--spacing-xl);
}