.switchWrapper {
    width: 100%;
    display: flex;
    gap: var(--spacing-sm);
    font: var(--font-button);
    align-items: center;
}

.switch {
    position: relative;
    display: inline-block;
    min-width: 80px;
    height: 40px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--col-grey-2);
    transition: .4s;
}



.slider.disabled {
    background-color: var(--col-grey-2) !important;
}

.slider:before {
    position: absolute;
    content: "";
    height: 32px;
    width: 32px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

.sliderCircleIconLine {
    position: absolute;
    content: "";
    height: 14px;
    width: 2px;
    background-color: var(--col-lavendar);
    left: 20px;
    bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .4s;
}

.sliderCircleIconLine.disabled {
    background-color: var(--col-grey-2);
}

.sliderCircleIconCircle {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    border: 2px solid var(--col-grey-4);
    border-radius: 30px;
    left: 13px;
    bottom: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .4s;
}

.sliderCircleIconCircle.disabled {
    border: 2px solid var(--col-grey-2);
}


input:checked~.sliderCircleIconLine,
input:checked~.sliderCircleIconCircle {
    -webkit-transform: translateX(36px);
    -ms-transform: translateX(40px);
    transform: translateX(40px);
}


input:checked+.slider {
    background-color: var(--col-lavendar)
}

input:checked+.slider:before {
    -webkit-transform: translateX(40px);
    -ms-transform: translateX(40px);
    transform: translateX(40px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}


.slider.round:before {
    border-radius: 50%;
}