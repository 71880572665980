.navigationList {
    width: 40px;
    /* width of full sidebar minus paddig on pageWrapper*/
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.navigationListMenuWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.pageNavigationItem {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 11px;
    width: 40px;
    height: 40px;
    border-radius: var(--border-radius-full);
    gap: var(--spacing-sm);
    color: var(--col-black-60);
    font-family: var(--font-primary);
    font-size: var(--text-md);
    border: solid 1px transparent;
    z-index: 1000;
}

.pageNavigationItem:hover {
    animation-name: unfold;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
}

.pageNavigationItem:hover .pageNavigationItemTitle {
    display: block;
    animation-name: appear;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-delay: 0.2s;
}

@keyframes unfold {
    0% {
        width: 40px;
        justify-content: flex-start;

    }

    100% {
        width: calc(100% + 80px);
        background-color: white;
        border: solid 1px var(--col-primary);

    }
}


.pageNavigationItemTitle {
    position: absolute;
    display: none;
    opacity: 0;
    top: 50%;
    left: calc(50% + 15px);
    transform: translate(-50%, -50%);


}

@keyframes appear {
    0% {
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
        color: var(--col-black);
    }
}

.pageNavigationItem__selected {
    background-color: white;
    border: solid 1px var(--col-primary);
}