.greyOpacityText {
    color: var(--col-grey-4)
}

.mediumHeader {
    color: var(--col-black);
    font-size: var(--text-lg);
}

.bodyOne {
    font: var(--font-body-1)
}

.body2 {
    font: var(--font-body-2);
}

.header2 {
    font: var(--font-h2)
}