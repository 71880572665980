@import './variables.css';
@import './typography.css';
@import './font.css';

/* import tailwind layers */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Outfit;
  background-color: var(--col-grey-1);
  color: var(--col-black);
  font-size: var(--text-md);
  font-weight: 500;
}

.grey-line {
  min-height: 1px;
  background-color: var(--col-grey-3);
}