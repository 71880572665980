.fullPageWrapperToCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    height: 100vh;
    width: 88px;
}

/* .whiteMainWrapper {
    position: absolute;
    top: var(--header-height); */
.mainWrapper {
    right: 0;
    border-radius: var(--border-radius-xl) var(--border-radius-xl) 0 0;
    width: calc(100vw - 88px);
    height: calc(100vh - var(--header-height));
    background-color: white;
    border: 1px solid var(--col-grey)
}

.pageBody {
    height: calc(100vh - var(--header-height));
    display: flex;
    gap: var(--spacing-lg);
    width: 100%;
    /* 86px is the height of the header*/
}

.mainWrapper__white {
    background-color: white;
}

.mainWrapper__primary {
    background-color: var(--col-primary);
}

.pageWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100vh;
    overflow: hidden;
    padding: 0 var(--spacing-md);
}