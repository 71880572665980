.header {
    display: flex;
    min-height: var(--header-height);
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.headerFilterWrapper {
    display: flex;
    background-color: white;
    border: var(--col-grey) 1px solid;
    width: 930px;
    height: 48px;
    border-radius: var(--border-radius-xl);
    padding: var(--spacing-sm);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.kpiList {
    height: 40px;
    display: flex;
    width: 384px;
    justify-content: space-between;
    padding: 5px 30px;
    align-items: center;
}

.kpiList__group {
    display: flex;
    gap: var(--spacing-sm);
}