.circle {
    min-width: 16px;
    min-height: 16px;
    border-radius: 16px;
}

.circleRed {
    background-color: var(--col-red);
    outline: 4px solid var(--col-red-border);
}

.circleGreen {
    background-color: var(--col-after-eight);
    outline: 4px solid var(--col-green-border);
}

.circleYellow {
    background-color: var(--col-taxi-yellow);
    outline: 4px solid var(--col-taxi-yellow-20);
}

.circlePurple {
    background-color: var(--col-lavendar);
    outline: 4px solid var(--col-lavendar-20);
}