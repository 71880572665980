.small-font-opacity-60-white {
    font-size: var(--text-sm);
    opacity: 0.6;
}

h1 {
    font: var(--font-h1)
}

h3 {
    font: var(--font-h3)
}