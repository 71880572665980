.accordion {
    height: 40px;
    width: 240px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--border-radius-full);
    padding: 4px 20px;
}

.accordion__primary {
    background-color: var(--col-grey-1);
    border: 1px solid var(--col-grey-2);
    color: var(--col-grey-5);
}

.accordion__secondary {
    color: var(--col-black);
}

.accordionList {
    z-index: 1000;
    position: absolute;
    top: 42px;
    width: 240px;
    border-radius: 20px;
    padding: var(--spacing-sm) var(--spacing-md);
    box-shadow: var(--boxshadow-light);
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.accordionList li {
    height: 40px;
    color: var(--col-black-60);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.accordionListItem__selected {
    color: var(--col-black) !important;
}

.accordionList__primary {
    background-color: var(--col-grey-1);
    border: 1px solid var(--col-grey-2);
}

.accordionList__secondary {
    background-color: white;
}