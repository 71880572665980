.zoomWrapper {
    background-color: white;
    width: 28px;
    height: 90px;
    gap: var(--spacing-sm);
    padding: 4px;
    border: 1px solid var(--col-grey-2);
    position: absolute;
    top: auto;
    right: var(--spacing-lg);
    bottom: 110px;
    border-radius: var(--border-radius-full);
    display: flex;
    flex-direction: column;
    align-items: center;
}