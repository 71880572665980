.nexusPlanInfo {
    display: flex;
    align-items: center;
    height: 60px;
    position: absolute;
    gap: var(--spacing-md);
    bottom: var(--spacing-xl);
    right: var(--spacing-lg);
    padding: var(--spacing-sm) var(--spacing-md);
    transition: 0.3s;
    overflow: hidden;
}