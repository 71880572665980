.verticalWorkTask {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 2px var(--spacing-xs);
    border-radius: var(--border-radius-sm);
}

.verticalAuxiliaryTask {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 2px var(--spacing-xs);
    border-radius: var(--border-radius-sm);
    border-left: 4px solid var(--col-mocha-cappucino);
    border-bottom: 1px solid var(--col-mocha-cappucino);

}

.verticalTaskText {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--col-black);
    font-size: var(--text-sm);
    padding: 0 5px;
    line-height: 14px;
}

.taskList>li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacing-xs);
    padding: 0 5px;
}

.taskList {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.transportationTask {
    border: 1px solid var(--col-grey-4);
    border-left: 4px solid var(--col-grey-4);
    background-color: var(--col-grey-3);
    border-radius: 5px;
    display: flex;
    gap: var(--spacing-sm);
    justify-content: center;
    align-items: center;
    position: absolute;
}

/* Badge for indicating whether a task has been moved between "teams" (shifts grouped by colour in Nexus) */
.crossTeamBadgeContainer {
    width: 14px;
    height: 14px;
    border-radius: var(--border-radius-full);
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: var(--col-black);
    right: 10px;
    top: 10px;
}

.crossTeamBadgeContainer>p {
    color: var(--text-invert);
    font-size: var(--text-sm);
}