.hoverBox {
    width: 420px;
    padding: var(--spacing-md) var(--spacing-lg) var(--spacing-lg) var(--spacing-lg);
    background-color: #222222F2;
    position: relative;
    z-index: 100000;
    color: white;
    gap: var(--spacing-xl);
    display: flex;
    flex-direction: column;
    height: fit-content;

}

.smallHoverBox {
    padding: var(--spacing-md) var(--spacing-lg);
    background-color: #222222F2;
    z-index: 1000;
    color: white;
    gap: var(--spacing-xl);
    position: absolute;
    top: full;
    border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) var(--border-radius-sm);
    left: 12px;
    width: fit-content;
    white-space: nowrap;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.body {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    font-size: var(--text-sm);
    font-weight: 400;
}

.titleWrapper {
    display: flex;
    gap: var(--spacing-sm);
    width: 172px;
    align-items: center;
}

.title {
    align-items: center;
    width: fit-content;
    font-weight: 700;
    font-size: var(--text-lg);
    overflow: hidden;
    /* Hide overflow content */
    white-space: nowrap;
    /* Prevent text wrapping */
    text-overflow: ellipsis;
}

.timeBlock {
    min-width: 155px;
    display: flex;
    align-items: center;
    gap: var(--spacing-sm)
}

.timestamp {
    font-size: var(--text-sm);
    display: flex;
}

.duration {
    font-size: var(--text-sm);
    display: flex;
    width: 70px;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-sm);
}

.hoverBoxList {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
}

.hoverBoxViolationText {
    color: var(--col-dirt-rose-dark);
    display: flex;
    gap: var(--spacing-sm);
    align-items: flex-start;
    line-height: 14px;
}