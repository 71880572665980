.datepickerSubstitute {
    width: 130px !important;
    position: absolute;
    pointer-events: none;
}

.datepickerWrapper {
    width: 145px !important;
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: center;
}

.datepickerWrapper input {
    width: 145px !important;
}

.datepickerWrapper input:focus {
    outline: none;
}