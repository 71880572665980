/* Scale */
.scale {
    width: 548px;
    border-radius: var(--border-radius-full);
    height: 27px;
}

.scaleFullLine {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.scaleWrapper {
    width: 548px;
    position: relative;
}

.scaleTip {
    position: absolute;
    height: 17px;
    width: 17px;
    border-radius: var(--border-radius-full);
    top: 5px;
    background-color: white;
}

.scaleText {
    font: var(--font-body-1);
}

.title {
    width: 35%;
    display: flex;
    align-items: center;
}

.value {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


/* TextLine */
.textLine {
    font: var(--font-body-1);
}

.boldTextLine {
    font: var(--font-h3);
}


/* Worktime */
.graphBars {
    height: 265px;
    width: 41px;
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius-sm);
}

.individualGraphBars {
    border-radius: var(--border-radius-sm);
}

.kpiChip {
    min-width: 65px;
    height: 30px;
    border-radius: var(--border-radius-sm);
    display: flex;
    justify-content: center;
    align-items: center;
    font: var(--font-body-3);
    gap: var(--spacing-sm);
}