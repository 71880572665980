.warningBox {
    border: 2px solid var(--col-taxi-yellow-light);
    border-radius: var(--border-radius-sm);
    padding: var(--spacing-md);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
}


.warningBoxText {
    font: var(--font-body-400);
    margin-left: 22px;
}

.warningBoxTitle {
    font: var(--font-h5);
}