:root {
    --font-primary: 'Outfit';
    /* colors */
    --text-primary: #222;
    --text-invert: #FFF;
    --col-secondary: #C4BFFF;
    --col-mocha-late: #F6EDE2;
    --col-mocha-late-50: rgb(246, 237, 226, 0.5);
    --col-mocha-flat-white: #DDD3CA;
    --col-mocha-cappucino: #BEB4AC;
    --col-primary: rgb(246, 237, 226);
    --col-primary-1: rgb(251, 246, 241);
    --col-primary-2: rgb(221, 211, 202);
    --col-primary-3: rgb(190, 180, 172);
    --col-primary-40: rgba(246, 237, 226, 0.4);
    --col-lavendar: #C4BFFF;
    --col-lavendar-20: rgb(196, 191, 255, 0.2);
    --col-lavendar-50: #C4BFFF80;
    --col-lavendar-light: #DDDAFF80;
    --col-lavendar-dark: #786EEE;
    --col-lavendar-dark-20: rgb(120, 110, 238, 0.2);
    --col-dirt-rose-dark: #FF6E6E;
    --col-dirt-rose: #FFA8A8;
    --col-dirt-rose-20: #FFA8A833;
    --col-dirt-rose-very-light: #FFE0E0;
    --col-taxi-yellow: #FFC806;
    --col-taxi-yellow-light: #FFEFB6;
    --col-taxi-yellow-20: rgb(255, 200, 6, 0.2);
    --col-after-eight: #6CD6A9;
    --col-after-eight-light: #E4F4EE;
    --col-after-eight-30: #6cd6a94d;
    --col-green-border: #38584B;
    --col-red: #FFA8A8;
    --col-red-border: #644A4A;
    --col-black: #222222;
    --col-black-60: rgba(34, 34, 34, 0.6);
    --col-grey: #EBEBEB;
    --col-grey-1: #f9f9f9;
    --col-grey-2: #EBEBEB;
    --col-grey-3: #d3d3d3;
    --col-grey-4: #909090;
    --col-grey-5: #707070;
    --boxshadow: 0px 4px 100px 0px rgb(0, 0, 0, 0.4);
    --boxshadow-light: 0px 6px 10px 0px rgb(0, 0, 0, 0.1);
    --col-success-main: #63D0A1;
    --col-success-1: #DFF4EC;
    --col-success-2: #6CD6A94D;
    --col-success-3: #6CD6A9;
    --col-success-4: #6CD6A9;
    --col-error-main: #FE9F9F;
    --col-error-1: #FFA8A833;
    --col-error-2: #FFDCDC;
    --col-error-3: #F85050;
    --col-error-4: #F85050;

    /* Font */
    --text-sm: 12px;
    --text-md: 14px;
    --text-lg: 20px;
    --text-xl: 44px;

    /* Border */
    --border-radius-sm: 5px;
    --border-radius-md: 9px;
    --border-radius-xl: 16px;
    --border-radius-full: 50px;

    /* header */
    --header-height: 86px;

    /* Spacing */
    --spacing-xs: 4px;
    --spacing-sm: 8px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    --spacing-xl: 32px;

    /* Font weight 700*/
    --font-h5: 700 14px "Outfit";

    /* Font weight 600*/
    --font-h1: 600 32px "Outfit";
    --font-h2: 600 24px "Outfit";
    --font-h3: 600 18px "Outfit";
    --font-h4: 600 14px "Outfit";


    /* Font body*/
    --font-body-400: 400 14px "Outfit";
    --font-body-1: 400 18px "Outfit";
    --font-body-2: 400 14px "Outfit";
    --font-body-3: 400 12px "Outfit";

    /* Font Other */
    --font-button: 500 14px "Outfit";
}