.kpiCard {
    border-radius: var(--border-radius-md);
    border: var(--col-primary) 1px solid;
    padding: var(--spacing-lg);
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg)
}

.kpiCardList {
    position: absolute;
    top: calc(var(--header-height) + var(--spacing-lg));
    left: calc(50% - 90px);
    transform: translateX(-50%);
    width: 80%;
    max-width: 1214px;
    /* margin: auto; */
    display: flex;
    gap: var(--spacing-lg);
    flex-wrap: wrap;
}

.kpiCardHeader {
    display: flex;
    gap: var(--spacing-sm);
    align-items: center;
    height: 65px;
    border-bottom: solid 1px var(--col-grey-2);
}