.violationWrapper {
    width: fit-content;
    height: 24px;
    padding: var(--spacing-xs) var(--spacing-sm);
    color: white;
    background-color: var(--col-red);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-full);
    gap: var(--spacing-sm);
}

.violationChipWrapper {
    width: fit-content;
    height: 25px;
    padding: var(--spacing-xs) var(--spacing-sm);
    border: 2px solid var(--col-dirt-rose);
    background-color: var(--col-grey-1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    gap: var(--spacing-sm);
}

.violationBox {
    border: 2px solid var(--col-dirt-rose);
    border-radius: var(--border-radius-sm);
    padding: var(--spacing-md);
    color: var(--col-dirt-rose-dark);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
}


.violationBoxText {
    font: var(--font-body-400);
    margin-left: 22px;
}

.violationBoxList {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
}

.violationBoxTitle {
    font: var(--font-h5);
}